
import Web3 from "web3";
import React, { useEffect, useState } from "react";
import Web3Modal from "web3modal";
import ReactLoading from 'react-loading';

import { contract_address, contract_abi,chain_id, speedy_nodes} from './config';
const Main = () => {
  let [count, setCount] = useState(1);
  const [newprice, setNewPrice] = useState(2000);
  const [totalMinted, setTotalMinted] = useState("");
  const [isWalletConnected,setisWalletConnected]= useState(false);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {

    fetch_data_before();
    connect_wallet();   
}, [])


async function connect_wallet(){

  if(Web3.givenProvider){
    const providerOptions = {
      /* See Provider Options Section */
    };

    const web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions // required
    });
    
    const provider = await web3Modal.connect();
    provider.on("accountsChanged", (accounts) => {
      console.log(accounts);
      window.location.reload();
    });
    
    // Subscribe to chainId change
    provider.on("chainChanged", (chainId) => {
      console.log(chainId);
      window.location.reload();
    });
    
    // Subscribe to provider connection
    provider.on("connect", (info) => {
      console.log(info);
      window.location.reload();
    });
    
    // Subscribe to provider disconnection
    provider.on("disconnect", (error) => {
      console.log(error);
      window.location.reload();
    });
    
    const web3 = new Web3(provider);
    const addresses = await web3.eth.getAccounts()
     let address = addresses[0];
     //address = "0xE436c5c45D5B8b9DeDF37A59d9BE8AE7864b26CB";
    //  setWeb3Global(web3);
    // setConnectedAddress(address);
    // setShortenAddress(address.slice(0, 4) + "..." + address.slice(address.length - 3, address.length))
    //alert("Connected")
    web3.eth.net.getId().then((result) => { 
  
    console.log("Network id: "+result)
    if(result !== parseInt(chain_id)){ //result !== 1
        //alert("Wrong Network Selected. Select Goerli Test Network and Refresh Page");
        try{
          window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: web3.utils.toHex(chain_id) }]
          });
        }catch(e){
          alert("Error in Switching Chain, Make Sure you have added cronos chain in your web3 wallet.");
          window.location.reload();
        }

      }else{
      //   const contract = new web3.eth.Contract(contract_abi, contract_address);

      setisWalletConnected(true);
      // setStateOne(!stateOne);
      // fetch_Data_After_Wallet_Connect(address);
      // setShowPopup(false);
          // get_nfts(address);
          // get_data(web3, address)
      }
    })

  }else{
    alert("Web3 Not Found. Try refreshing if you have metamask installed.");
  }
  
}
async function fetch_data_before(){

  const web3 = new Web3(speedy_nodes);
  const contract = new web3.eth.Contract(contract_abi, contract_address);
  //await Web3.givenProvider.enable()

  contract.methods.totalSupply().call((err,result) => {
    console.log("error: "+err);
    if(result != null){
       setTotalMinted(result)
    }
})

contract.methods.price().call((err,result) => {
console.log("error: "+err);
if(result != null){
  setNewPrice(parseInt(web3.utils.fromWei(result.toString(), "ether")))
  console.log("Getting price: "+result + " /// After web3: "+ web3.utils.fromWei(result.toString(), "ether"))
}
})

}
async function show_error_alert(error){
  let temp_error = error.message.toString();
  console.log(temp_error);
  let error_list = [
    "SALE_HAS_NOT_STARTED_YET",
    "NOT_ENOUGH_SUPPLY_TO_MINT_DESIRED_AMOUNT",
    "CANNOT_MINT_THAT_MANY",
    "It's not time yet",
    "Sent Amount Wrong",
    "INVALID_SGB",
    "Max Supply Reached",
    "You have already Claimed Free Nft.",
    "Presale have not started yet.",
    "Presale Ended.",
    "You are not Whitelisted.",
    "Sent Amount Not Enough",
    "Max 20 Allowed.",
    "insufficient funds",
    "Exceeding Per Tx Limit",
    "mint at least one token",
    "incorrect ether amount",
    "Presale Ended.",
    "Sale is Paused.",
    "You are not whitelisted",
    "Invalid Voucher. Try Again.",
    "Max Supply Reached.",
    "Public sale is not started",
    "Needs to send more eth",
    "Public Sale Not Started Yet!",
    "Exceed max adoption amount",
    "Private Sale Not Started Yet!",
    "Exceed max minting amount"
  ]

  for(let i=0;i<error_list.length;i++){
    if(temp_error.includes(error_list[i])){
     // set ("Transcation Failed")
      alert(error_list[i]);
    }
  }
}
async function mint_public(){

  if(Web3.givenProvider ){ 
    setisLoading(true);
    const web3 = new Web3(Web3.givenProvider);
    await Web3.givenProvider.enable()
    const contract = new web3.eth.Contract(contract_abi, contract_address);

    const addresses = await web3.eth.getAccounts()
    const address = addresses[0]
    console.log("addresses[0]: "+addresses[0])

    let price = newprice * count;
     price = Math.round(price * 100) / 100;
    console.log("Price: "+price+ "  ......... count:   "+count);
  //   price =0.006;
    try{
      const estemated_Gas = await contract.methods.mint(count.toString()).estimateGas({
        from : address, 
        value: web3.utils.toWei(price.toString(), "ether"),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.mint(count.toString()).send({
        from : address,
        value: web3.utils.toWei(price.toString(), "ether"),
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })
      await fetch_data_before();
      setisLoading(false);
      alert("Mint Successful")

    }catch(e){
      setisLoading(false);
      show_error_alert(e);
    }

   // await contract.methods.tokenByIndex(i).call();
  }else{
    alert("Web3 Not Found. Try refreshing if you have metamask installed.");
  }

}
async function mint_public_count(t_count){

  if(Web3.givenProvider ){ 
    setisLoading(true);
    let count = t_count;
    const web3 = new Web3(Web3.givenProvider);
    await Web3.givenProvider.enable()
    const contract = new web3.eth.Contract(contract_abi, contract_address);

    const addresses = await web3.eth.getAccounts()
    const address = addresses[0]
    console.log("addresses[0]: "+addresses[0])

    let price = newprice * count;
     price = Math.round(price * 100) / 100;
    console.log("Price: "+price+ "  ......... count:   "+count);
  //   price =0.006;
    try{
      const estemated_Gas = await contract.methods.mint(count.toString() ).estimateGas({
        from : address, 
        value: web3.utils.toWei(price.toString(), "ether"),
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      });
      console.log(estemated_Gas)
      const result = await contract.methods.mint(count.toString()).send({
        from : address,
        value: web3.utils.toWei(price.toString(), "ether"),
        gas: estemated_Gas,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null
      })

      await fetch_data_before();
      setisLoading(false);
      alert("Mint Successful")

    }catch(e){
      setisLoading(false);
      show_error_alert(e);
    }

   // await contract.methods.tokenByIndex(i).call();
  }else{
    alert("Web3 Not Found. Try refreshing if you have metamask installed.");
  }

}
  const incrementCount = () => {
    count = count + 1;
    setCount(count);
  }

  const decrementCount = () => {
    count = count - 1;
    setCount(count);
  }

  return (
    <>
    
      <div className="main" >
        <div className="bg_img">
        <video loop autoPlay muted>
          <source src='/images/bg-video.mp4' />
        </video>
        </div>

{/* 
        {isLoading && (
  <div className={`modal`}tabIndex='-1'>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header d-block text-center">
          <h1 className="modal-title fs-5" id="exampleModalLabel">Minting</h1>
            </div>
        <div className="modal-body">
        <ReactLoading type="spin" color="#36AD49" height={50} width={50} />
        </div>asd
      </div>
    </div>
  </div>
)} 

*/}

        <div className="container">
          <div className="heading">
            {/* <p>The</p> */}
            <h2>SPARKTANS</h2>
          </div>

          {/* <ul className='social-list'>
            <li className='icon'>
              <a href="https://www.twitter.com" target='_blank'  rel="noopener noreferrer">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" color="#ffcd2a" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
              </a>
            </li>
            <li className='icon'>
              <a href="https://www.discrod.com" target='_blank'  rel="noopener noreferrer">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 640 512" color="#ffcd2a" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M524.531,69.836a1.5,1.5,0,0,0-.764-.7A485.065,485.065,0,0,0,404.081,32.03a1.816,1.816,0,0,0-1.923.91,337.461,337.461,0,0,0-14.9,30.6,447.848,447.848,0,0,0-134.426,0,309.541,309.541,0,0,0-15.135-30.6,1.89,1.89,0,0,0-1.924-.91A483.689,483.689,0,0,0,116.085,69.137a1.712,1.712,0,0,0-.788.676C39.068,183.651,18.186,294.69,28.43,404.354a2.016,2.016,0,0,0,.765,1.375A487.666,487.666,0,0,0,176.02,479.918a1.9,1.9,0,0,0,2.063-.676A348.2,348.2,0,0,0,208.12,430.4a1.86,1.86,0,0,0-1.019-2.588,321.173,321.173,0,0,1-45.868-21.853,1.885,1.885,0,0,1-.185-3.126c3.082-2.309,6.166-4.711,9.109-7.137a1.819,1.819,0,0,1,1.9-.256c96.229,43.917,200.41,43.917,295.5,0a1.812,1.812,0,0,1,1.924.233c2.944,2.426,6.027,4.851,9.132,7.16a1.884,1.884,0,0,1-.162,3.126,301.407,301.407,0,0,1-45.89,21.83,1.875,1.875,0,0,0-1,2.611,391.055,391.055,0,0,0,30.014,48.815,1.864,1.864,0,0,0,2.063.7A486.048,486.048,0,0,0,610.7,405.729a1.882,1.882,0,0,0,.765-1.352C623.729,277.594,590.933,167.465,524.531,69.836ZM222.491,337.58c-28.972,0-52.844-26.587-52.844-59.239S193.056,219.1,222.491,219.1c29.665,0,53.306,26.82,52.843,59.239C275.334,310.993,251.924,337.58,222.491,337.58Zm195.38,0c-28.971,0-52.843-26.587-52.843-59.239S388.437,219.1,417.871,219.1c29.667,0,53.307,26.82,52.844,59.239C470.715,310.993,447.538,337.58,417.871,337.58Z"></path></svg>
              </a>
            </li>
            <li className='icon'>
              <a href="https://www.t.com" target='_blank'  rel="noopener noreferrer">
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" color="#ffcd2a" height="30" width="30" xmlns="http://www.w3.org/2000/svg"><path d="M446.7 98.6l-67.6 318.8c-5.1 22.5-18.4 28.1-37.3 17.5l-103-75.9-49.7 47.8c-5.5 5.5-10.1 10.1-20.7 10.1l7.4-104.9 190.9-172.5c8.3-7.4-1.8-11.5-12.9-4.1L117.8 284 16.2 252.2c-22.1-6.9-22.5-22.1 4.6-32.7L418.2 66.4c18.4-6.9 34.5 4.1 28.5 32.2z"></path></svg>
              </a>
            </li>
          </ul> */}

          <div className="main-gif">
            <div className="main-gif-inner">
              <div className="inner">
                <img src="/images/example.gif" alt="Minting" />
              </div>
            </div>
          </div>

          <div className="text">
            <strong className='main-text'>
              <span>{totalMinted}/11</span>,<span>111</span>
            </strong>
            <small className="big-text"><span></span> 2,222 SGB</small>
          </div>
          {isLoading && (
          <ReactLoading id="loading" type="spin" color="white" height={50} width={50} />
          )}

          <div className="quantity-wrapper">
            <div className="quantity">
              <button className='btn btn-minus' onClick={decrementCount}>-</button>
              <div className='val'>{count}</div>
              <button className='btn btn-plus' onClick={incrementCount}>+</button>
            </div>
            <button className='btn btn-connect' onClick={mint_public}>
              Mint
            </button> 
          </div>
          <div className='button-size'>
          <button className='btn btn-connect1' onClick={() => {
            mint_public_count(5);
          }}>
          Agoge (5x)
            </button>
          <button className='btn btn-connect1' onClick={() =>{
            mint_public_count(10);
          }}>
          Phalanx (10x)
            </button>
          </div>
          <div className="text">
          <a className="big-text" href="https://points.sparkcoin.io/" target='_blank'  rel="noopener noreferrer">View your Sparktans</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default Main;